<template>
    <div class="office-divisions">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Divisiones de Oficina',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de divisiones para oficinas, más de 30 años fabricando divisiones para oficina en bucaramanga' },
			{ name: 'keywords', content:'divisiones, divisiones oficina, divisiones bucaramanga, divisiones oficina bucaramanga, divisiones para oficina bucaramanga, divisiones en vidrio, divisiones vidrio bucaramanga ' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Divisiones de Oficina'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de divisiones para oficinas, más de 30 años fabricando divisiones para oficina en bucaramanga'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Divisiones de Oficina'},
			{name: 'twitter:description', content: 'Fabrica de divisiones para oficinas, más de 30 años fabricando divisiones para oficina en bucaramanga' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Divisiones de Oficina'},
			{itemprop: 'description', content: 'Fabrica de divisiones para oficinas, más de 30 años fabricando divisiones para oficina en bucaramanga' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Divisiones para oficina',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'División 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'División 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_2.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'División 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/divisiones-para-oficinas/productos/division_3.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ],
            }
        }
    }
}
</script>